<template>
  <div class="table-wrapper">
    <div 
      class="table-responsive"
      v-bind:class="{ 'spinner-is-visible': isSpinnerVisible }"
    >
      <table class="table">
        <thead>
          <tr>
            <SortableHeader
              :current-sort="orderBy"
              :sort-by="'NAME'"
              @sort="reorder"
            >Component</SortableHeader>

            <SortableHeader
              class="text-center"
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="worstCaseDamageColumnSortBy"
              @sort="reorder"
            >
              <InfoBadge
                content="<strong>Worse Case Damage:</strong> This value is calculated for each component of an asset based on the worst rating among the approved findings for the most recent project for that component. The overall asset rating is then calculated based on the worst rating among those components.<br><br>Note: If one (or more) projects are included in the filter, the calculation is the same but the ratings will only be based on those filtered projects."
              />
              Worst Case Damage
            </SortableHeader>

            <SortableHeader
              v-if="showLeCondition"
              class="text-center"
              style="width: 200px"
              :current-sort="orderBy"
              :sort-by="leadingEdgeWorstCaseDamageColumnSortBy"
              @sort="reorder"
            >Leading Edge Condition</SortableHeader>

            <SortableHeader
              class="text-end"
              style="width: 150px"
              :current-sort="orderBy"
              :sort-by="activityDateColumnSortBy"
              @sort="reorder"
            >Last Activity</SortableHeader>

            <th
              v-if="showReplacements"
              class="text-end"
              style="width: 150px"
            >Replacement Date</th>

            <th data-sorter="false"></th>
          </tr>
        </thead>

        <tbody>
          <AssetComponent
            v-for="(item, index) in items"
            v-bind="item"
            :key="item.id"
            :filter="filter"
            :index="index"
            :policy-update="policyUpdate"
            :show-in-preview-mode="isFilterInPrevievMode"
            :show-le-condition="showLeCondition"
            :show-replacements="showReplacements"
            :ref="item.id"
            :selected="isItemMarked(item.id)"
          />
        </tbody>
      </table>

      <div class="spinner"></div>

      <NoRecords 
        label="According to the search criteria, there are no components found."
      />

      <Pagination
        v-bind="pageInfo"
        entity-type="components"
        @navigate-to="paginate"
      />
    </div>
  </div>
</template>

<script>
import Grid from "../shared/grid/grid";

import AssetComponent from "./component";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Grid,

  components: {
    AssetComponent,
    InfoBadge
  },

  props: {
    physicalAssetId: String,
    showLeCondition: Boolean,
    showReplacements: Boolean
  },

  data: function () {
    return {
      items: [],

      grid: "components",

      defaultOrderBy: {
        field: "NAME",
        direction: "ASC"
      },
      
      orderBy: {
        field: "NAME",
        direction: "ASC"
      },

      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        totalPageCount: 0
      },

      isSpinnerVisible: true
    }
  },

  watch: {
    filter: function() {      
      if (["APPROVED_ACTIVITY_DATE", "PREVIEW_ACTIVITY_DATE"].includes(this.orderBy.field)) {
        this.orderBy.field = this.activityDateColumnSortBy;
      }

      if (["CLIENT_REMEDIATED_RATING", "REMEDIATION_PREVIEW_RATING"].includes(this.orderBy.field)) {
        this.orderBy.field = this.worstCaseDamageColumnSortBy;
      }

      if (["LEADING_EDGE_CLIENT_REMEDIATED_RATING", "LEADING_EDGE_REMEDIATION_PREVIEW_RATING"].includes(this.orderBy.field)) {
        this.orderBy.field = this.leadingEdgeWorstCaseDamageColumnSortBy;
      }

      if (this.isProjectFilter) {
        this.orderBy.projectId = this.filter.projectId[0];
      } else {
        delete this.orderBy["projectId"];
      }
    },
  },

  computed: {
    // grid.js overriden method for more complex sorting
    queryOrder: function () {
      if (this.isProjectFilter) {
        this.orderBy.projectId = this.filter.projectId[0];
      } else {
        delete this.orderBy["projectId"];
      }

      return this.orderBy;
    },

    activityDateColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "PREVIEW_ACTIVITY_DATE" : "APPROVED_ACTIVITY_DATE";
    },

    worstCaseDamageColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "REMEDIATION_PREVIEW_RATING" : "CLIENT_REMEDIATED_RATING";
    },

    leadingEdgeWorstCaseDamageColumnSortBy: function() {
      return this.isFilterInPrevievMode ? "LEADING_EDGE_REMEDIATION_PREVIEW_RATING" : "LEADING_EDGE_CLIENT_REMEDIATED_RATING";
    },

    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["physicalAssetId", "siteId", "historical", "status"].includes(x))).length === 0 && this.filter.historical == false;
    },

    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    isProjectFilter: function () {
      return (this.filter.projectId != undefined && this.filter.projectId !== [] && this.filter.projectId.length == 1);
    },

    filterParams: function () {
      delete this.filter.siteId;

      let params = {
        physicalAssetId: this.physicalAssetId,
      };

      if (!this.isFilterEmpty || this.isFilterInHistoricalMode || this.isFilterInPrevievMode) {
        params.findings = this.filter;

        params.findings.physicalAssetId = this.physicalAssetId;
        params.findings.historical = this.filter.historical || false;
      };

      return params;
    },

    queryName: function () {
      if (this.isProjectFilter) {
        return "grid.asset.projectComponents";
      } else {
        return "grid.asset.components";
      }
    }
  },

  methods: {
    fetch: function (params, success) {
      app.graphql.get(this.queryName,
        this.queryParams(params),

        (data) => {
          let components = data.data.components,
              pageInfo = components.pageInfo;

          success(components, pageInfo);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch components!"
          })
        }
      )
    },

    queryParams: function (params) {
      if (this.isProjectFilter) {
        params.projectId = this.filter.projectId[0];
      }

      return params;
    },
  }
}
</script>
