
<template>
  <div class="modal-body">
    <downloadable-report-form
      ref="form"
      :report="report"
    ></downloadable-report-form>
  </div>
</template>

<script>
import DownloadableReportForm from '../../downloadable_reports/form';

export default {
  components: {
    DownloadableReportForm
  },

  props: {
    args: Object
  },

  data() {
    return {
      report: Object
    }
  },

  created: function() {
    if (this.args) {
      this.report = this.args.report;
    }
  }
}

</script>