<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div v-bind:class="{'hidden': !isLoaded }">
      <div class="row">
        <div class="col-sm-4 col-xs-12">
          <finding-components-filter
            v-if="isLoaded"
            ref="componentsFilter"
            :default-finding="finding"
            :default-client-company="defaultClientCompany"
            :default-project="defaultProject"
            :default-inspection="defaultInspection"
            :default-site="defaultSite"
            :default-asset="defaultAsset"
            :default-component="defaultComponent"
            :default-project-id="defaultProjectId"
            @change-component="setComponent"
          ></finding-components-filter>
        </div>
        <div class="col-sm-4 col-xs-12">
          <fieldset>
            <legend>Finding classification</legend>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <finding-classification
                  v-if="isLoaded"
                  ref="findingClassification"
                  placeholder="Rating"
                  name="finding[classification]"
                  id="finding_classification"
                  :default-rating="defaultRating"
                  @change="checkIfRatingRevision"
                ></finding-classification>
              </div>
            </div>
            <div @keydown.enter.prevent>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-static">Finding Status</label>
                    <v-select
                      placeholder="Select finding status"
                      ref="findingStatus"
                      :class="[{'vs-error': v$.findingStatus.$error}]"
                      :options="findingStatuses"
                      label="key"
                      @update:modelValue="setFindingStatus"
                    />
                    <div v-if="v$.findingStatus.$error" class="error">Finding status is required</div>
                    <input
                      type="hidden"
                      id="finding_status"
                      name="finding[status]"
                      :value="currentFindingStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-xs-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="finding_description">Finding Notes</label>
                  <textarea rows="4" class="form-control" placeholder="Finding notes" name="finding[description]" id="finding_description" v-model="finding.notes"></textarea>
                </div>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend>Inspected by</legend>
            <finding-inspectors
              ref="inspectors"
              :default-inspectors="defaultInspectors"
              :should-be-validated="!findingId || (findingId && defaultInspectors.length > 0)"
            ></finding-inspectors>
          </fieldset>

          <fieldset v-if="isRevision">
            <legend>Revision info</legend>
            <div @keydown.enter.prevent>
              <div class="row">
                <div class="col-sm-12 col-xs-12">
                  <div class="form-group">
                    <label class="placeholder placeholder-static">Revision Category</label>
                    <v-select
                      placeholder="Select revision category"
                      ref="revisionCategory"
                      :class="[{'vs-error': v$.revisionCategory.$error}]"
                      :options="revisionCategories"
                      @update:modelValue="setRevisionCategory"
                    />
                    <div v-if="v$.revisionCategory.$error" id="finding_revision_attributes_category-error" class="error">This field is required.</div>
                    <input
                      type="hidden"
                      id="finding_revision_attributes_category"
                      name="finding[revision_attributes][category]"
                      :value="revisionCategory"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="placeholder placeholder-static" for="finding_revision_attributes_description">Revision description</label>
                  <textarea rows="4" :class="['form-control', {'error': v$.revisionDescription.$error}]" placeholder="Revision description" name="finding[revision_attributes][description]" id="finding_revision_attributes_description" v-model.trim="v$.revisionDescription.$model"></textarea>
                  <div v-if="v$.revisionDescription.$error" id="finding_revision_attributes_description-error" class="error">This field is required.</div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="col-sm-4 col-xs-12">
          <finding-types-filter
            v-if="isLoaded"
            v-bind="{ 'defaultComponentTypeId': componentTypeId }"
            ref="findingTypesFilter"
            :default-finding="finding"
            :default-finding-type="defaultFindingType"
            :default-component-type-id="componentTypeId"
            @change-finding-type="checkIfFindingTypeRevision"
            @change-finding-description="checkIfFindingDescriptionRevision"
          ></finding-types-filter>
        </div>
      </div>
      <div class="form-group-button">
        <form-button
          ref="submitButton"
          :label="submitButtonLabel"
          @submit="submitForm"
        ></form-button>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import findingClassification from "./form_classifications";
import findingComponentsFilter from "./form_components_filter";
import findingTypesFilter from "./form_types_filter";
import findingInspectors from "./form_inspectors";
import formButton from "../shared/form/form_button";

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const isRequired = (param) => helpers.withParams({ type: 'contains', value: param }, (value) => param ? helpers.req(value) : true)

export default {
  components: {
    vSelect,
    findingClassification,
    findingComponentsFilter,
    findingTypesFilter,
    findingInspectors,
    formButton
  },

  props: {
    formId: String,
    findingId: String,
    defaultRating: String,
    defaultStatus: String,
    defaultClientCompany: Object,
    defaultProject: Object,
    defaultInspection: Object,
    defaultSite: Object,
    defaultAsset: Object,
    defaultComponent: Object,
    defaultFindingType: Object,
    defaultFindingDescription: String,
    defaultInspectors: Array,
    defaultProjectId: String
  },

  setup () {
    return {
      v$: useVuelidate()
    }
  },

  data() {
    return {
      finding: {
        id: this.fidingId,
        findingType: {},
        rating: "",
        inspection: {
          project: {}
        },
        component: {
          componentType: {},
          physicalAsset: {
            site: {
              clientCompany: {}
            },
          }
        },
        details: {},
        notes: ""
      },
      isLoaded: false,
      isSpinnerVisible: true,
      isRevision: false,
      isRatingRevision: false,
      isFindingTypeRevision: false,
      isFindingDescriptionRevision: false,
      componentTypeId: null,
      findingStatuses: [
        {key: "Draft", value: "draft"},
        {key: "Review", value: "review"},
        {key: "Approved", value: "approved"}
      ],
      findingStatus: "",
      revisionCategories: [
        "Findings import error",
        "Photos do not match report",
        "Rating criteria modified",
        "2nd review modification",
        "Final review modification"
      ],
      revisionCategory: "",
      revisionDescription: ""
    }
  },

  validations () {
    return {
      revisionCategory: {
        conditionalyRequired: isRequired(this.isRevision)
      },
      revisionDescription: {
        conditionalyRequired: isRequired(this.isRevision)
      },
      findingStatus: {
        required
      }
    }
  },

  mounted: function() {
    if (this.defaultStatus != "") {
      let vm = this;

      let currentStatus = vm.findingStatuses.find(status => {
        return status.value === vm.defaultStatus;
      });

      this.$refs.findingStatus.updateValue(currentStatus.key);
    }

    if (this.findingId != "") {
      this.fetchFinding();
    }
    else {
      this.isLoaded = true;

      setTimeout(() => {
        this.isSpinnerVisible = false;
      }, 1000);
    }
  },

  computed: {
    currentFindingStatus: function () {
      return this.findingStatus.toLowerCase();
    },

    submitButtonLabel: function() {
      return ((this.findingId != "")) ? "Update Finding" : "Create Finding";
    }
  },

  methods: {
    checkIfRatingRevision: function(selectedRating) {
      this.isRatingRevision = this.defaultRating != "" && this.defaultRating != selectedRating;

      this.checkIfRevision();
    },

    checkIfFindingTypeRevision: function(selectedFindingType) {
      if (selectedFindingType) {
        this.isFindingTypeRevision = (this.defaultFindingType.id != "" && this.defaultFindingType.id != undefined) && this.defaultFindingType.id != selectedFindingType.id;

        this.checkIfRevision();
      }
    },

    checkIfFindingDescriptionRevision: function(findingDescription) {
      if (findingDescription && this.defaultFindingDescription != "") {
        this.isFindingDescriptionRevision = (!isNaN(findingDescription) && !isNaN(this.defaultFindingDescription)) ? parseFloat(this.defaultFindingDescription) != parseFloat(findingDescription) : this.defaultFindingDescription != findingDescription;
      }

      this.checkIfRevision();
    },

    checkIfRevision: function() {
      this.isRevision = this.isRatingRevision || this.isFindingTypeRevision || this.isFindingDescriptionRevision;
    },

    setRevisionCategory: function(opt) {
      this.revisionCategory = (opt) ? opt : "";
    },

    setFindingStatus: function(opt) {
      this.findingStatus = (opt) ? ((opt.key) ? opt.value : opt) : "";
    },

    fetchFinding: function() {
      app.graphql.get("details.finding",
        {
          filter: {
            id: [this.findingId],
            status: ["review", "draft", "approved"]
          }
        },
        (data) => {
          let currentFinding = data.data.findings.edges[0].node;

          this.finding = {
            id: this.findingId,
            findingType: currentFinding.findingType,
            rating: currentFinding.rating.toString(),
            inspection: currentFinding.inspection,
            component: currentFinding.component,
            details: JSON.parse(currentFinding.details),
            notes: currentFinding.description
          }

          this.isLoaded = true;

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 1000);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch finding!"
          });
        }
      );
    },

    setComponent: function(component) {
      this.componentTypeId = (component) ? (component.component_type_id || component.componentType.id) : null;

      this.$refs.findingTypesFilter.$emit("set-component-type", this.componentTypeId);
    },

    submitForm: function() {
      const form = document.forms[this.formId];
      
      this.v$.$touch();

      if (form && this.v$.$errors.length <= 0) {     
        this.$refs.submitButton.loadingOn();

        form.submit();
      }
    }
  }
}

</script>
