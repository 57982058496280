/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { createApp } from 'vue/dist/vue.esm-bundler';

import ClientCompanies from '../components/client_companies/client_companies';
import ClientCompanySites from '../components/client_companies/sites';
import ClientCompanyHighRiskSites from '../components/client_companies/high_risk_sites';
import ClientCompanyProjects from '../components/client_companies/projects';
import ClientCompanyUsers from '../components/client_companies/users';
import ClientCompanyMap from '../components/client_companies/map';
import ClientCompanyForm from '../components/client_companies/form';

import ClientRolesForm from '../components/client_roles/form.vue';

import Sites from '../components/sites/sites';
import SitePhysicalAssets from '../components/sites/physical_assets';
import SiteFindings from '../components/sites/findings';
import SiteRemediations from '../components/sites/remediations';
import SiteReplacements from '../components/sites/replacements';
import SiteFindingsFilter from '../components/shared/filter/site_findings_filter';
import SiteMap from '../components/sites/map';
import SiteStatistics from '../components/sites/site_statistics';
import SiteForm from '../components/sites/form';
import SiteBulkUpdateButton from '../components/sites/site_bulk_update_button';
import SiteImportForm from '../components/site_imports/form';
import SiteImportTemplateForm from '../components/site_imports/form_site_import_template';
import SiteImportTemplateButton from '../components/site_imports/site_import_temaplate_button';

import PhysicalAssets from '../components/physical_assets/physical_assets';
import PhysicalAssetComponents from '../components/physical_assets/components';
import PhysicalAssetFindings from '../components/physical_assets/findings';
import PhysicalAssetFindingsFilter from '../components/shared/filter/physical_asset_findings_filter';
import PhysicalAssetMap from '../components/physical_assets/map';
import PhysicalAssetQrLabels from '../components/physical_assets/qr_labels';
import PhysicalAssetForm from '../components/physical_assets/form';
import PhysicalAssetQrAssignButton from '../components/physical_assets/physical_asset_qr_assign_button';

import PhysicalAssetTypes from '../components/physical_asset_types/physical_asset_types';
import PhysicalAssetTypeForm from '../components/physical_asset_types/form';

import Components from '../components/components/components';
import ComponentFindings from '../components/components/findings';
import ComponentFindingsFilter from '../components/shared/filter/component_findings_filter';
import ComponentForm from '../components/components/form';
import ComponentImportForm from '../components/component_imports/form';

import ComponentTypes from '../components/component_types/component_types';
import ComponentTypeForm from '../components/component_types/form';

import Projects from '../components/projects/projects';
import ProjectSites from '../components/projects/sites';
import ProjectInspections from '../components/projects/inspections';
import ProjectFindings from '../components/projects/findings';
import ProjectRemediations from '../components/projects/remediations';
import ProjectRevisions from '../components/projects/revisions';
import ProjectOverrides from '../components/projects/overrides';
import ProjectForm from '../components/projects/form';

import Inspections from '../components/inspections/inspections';
import InspectionFindings from '../components/inspections/findings';
import InspectionRemediations from '../components/inspections/remediations';
import InspectionForm from '../components/inspections/form';

import Findings from '../components/findings/findings';
import FindingsFilter from '../components/shared/filter/findings_filter';
import FindingsImportForm from '../components/finding_imports/form';
import FindingRevisions from '../components/findings/revisions';
import FindingForm from '../components/findings/form';
import FindingComponentsFilter from '../components/findings/form_components_filter';
import FindingTypesFilter from '../components/findings/form_types_filter';

import FindingTypeForm from '../components/finding_types/form';
import FindingTypes from '../components/finding_types/finding_types';

import Remediations from '../components/remediations/remediations';
import RemediationsFilter from '../components/shared/filter/remediations_filter';
import RemediationFindings from '../components/shared/details/related_findings';
import RemediationRevisions from '../components/remediations/revisions';
import RemediationForm from '../components/remediations/form';

import RemediationTypes from '../components/remediation_types/remediation_types';
import RemediationTypeForm from '../components/remediation_types/form';

import QrLabels from '../components/qr/labels';
import QrBatches from '../components/qr/batches';
import QrLabelForm from '../components/qr/label_form';

import Inspectors from '../components/inspectors/inspectors';
import InspectorForm from '../components/inspectors/form';

import Users from '../components/users/list';
import UserForm from '../components/users/form';

import UserAgreements from '../components/user_agreements/user_agreements';
import UserAgreementForm from '../components/user_agreements/form';

import DownloadableReports from '../components/downloadable_reports/reports';
import DownloadableReportForm from '../components/downloadable_reports/form';

import ReportGenerateButton from '../components/reports/generate_report_button';
import ReportSiteStatistics from '../components/reports/site_statistics';
import ReportMap from '../components/reports/map';

import Documents from '../components/documents/documents';
import PhotoGallery from '../components/shared/photo_gallery';
import DetailsPane from '../components/shared/details/details_pane.vue';
import Help from '../components/help/help';
import LoginForm from '../components/sessions/login_form';
import Modal from '../components/shared/modal/modal';
import Toasts from '../components/shared/toasts/toasts';

const controls = {
  ClientCompanies,
  ClientCompanySites,
  ClientCompanyHighRiskSites,
  ClientCompanyProjects,
  ClientCompanyUsers,
  ClientCompanyMap,
  ClientCompanyForm,

  ClientRolesForm,

  Sites,
  SitePhysicalAssets,
  SiteFindings,
  SiteRemediations,
  SiteReplacements,
  SiteFindingsFilter,
  SiteMap,
  SiteStatistics,
  SiteForm,
  SiteBulkUpdateButton,
  SiteImportForm,
  SiteImportTemplateForm,
  SiteImportTemplateButton,

  PhysicalAssets,
  PhysicalAssetComponents,
  PhysicalAssetFindings,
  PhysicalAssetFindingsFilter,
  PhysicalAssetMap,
  PhysicalAssetQrLabels,
  PhysicalAssetForm,
  PhysicalAssetQrAssignButton,

  PhysicalAssetTypes,
  PhysicalAssetTypeForm,

  Components,
  ComponentFindings,
  ComponentFindingsFilter,
  ComponentForm,
  ComponentImportForm,

  ComponentTypes,
  ComponentTypeForm,

  Projects,
  ProjectSites,
  ProjectInspections,
  ProjectFindings,
  ProjectRemediations,
  ProjectRevisions,
  ProjectOverrides,
  ProjectForm,

  Inspections,
  InspectionFindings,
  InspectionRemediations,
  InspectionForm,

  Findings,
  FindingsFilter,
  FindingsImportForm,
  FindingRevisions,
  FindingForm,
  FindingComponentsFilter,
  FindingTypesFilter,

  FindingTypes,
  FindingTypeForm,

  Remediations,
  RemediationsFilter,
  RemediationFindings,
  RemediationRevisions,
  RemediationForm,

  RemediationTypes,
  RemediationTypeForm,

  QrLabels,
  QrBatches,
  QrLabelForm,

  Inspectors,
  InspectorForm,

  Users,
  UserForm,

  UserAgreements,
  UserAgreementForm,

  DownloadableReports,
  DownloadableReportForm,

  ReportGenerateButton,
  ReportSiteStatistics,
  ReportMap,

  Documents,
  PhotoGallery,
  DetailsPane,
  Help,
  LoginForm,
  Modal,
  Toasts
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("[data-vue]").forEach((item) => {
    const vueControl = item.dataset.vue
    const kc = vueControl.replace(/([a-z0–9])([A-Z])/g, "$1-$2").toLowerCase()

    const vm = "vm" + vueControl
    const vmApp = createApp({})

    vmApp.component(kc, controls[vueControl])

    window[vm] = vmApp.mount("[data-vue='" + vueControl + "']")
  })
})
